// Generated by Framer (8857874)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX, withOptimizedAppearEffect } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFXWithOptimizedAppearEffect = withOptimizedAppearEffect(withFX(motion.div));

const cycleOrder = ["qnGqaWe1y"];

const serializationHash = "framer-MezKP"

const variantClassNames = {qnGqaWe1y: "framer-v-zdvyz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 30, delay: 0.2, mass: 1, stiffness: 100, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition1, x: 0, y: 0}

const animation1 = {opacity: 0.001, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 80}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qnGqaWe1y", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><MotionDivWithFXWithOptimizedAppearEffect {...restProps} {...gestureHandlers} __framer__presenceAnimate={animation} __framer__presenceInitial={animation1} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zdvyz", className, classNames)} data-border data-framer-appear-id={"zdvyz"} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qnGqaWe1y"} optimized ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-43c7dbfb-9cbe-4732-95da-b729698c8ff0, rgb(221, 222, 237))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-9f83d5e4-15d5-47d8-aed7-6e533a81b5c1, rgb(255, 255, 255))", borderBottomLeftRadius: 96, borderBottomRightRadius: 96, borderTopLeftRadius: 96, borderTopRightRadius: 96, ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MezKP.framer-fc9z06, .framer-MezKP .framer-fc9z06 { display: block; }", ".framer-MezKP.framer-zdvyz { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; min-height: 62px; min-width: 171px; overflow: visible; padding: 12px 24px 12px 12px; position: relative; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MezKP.framer-zdvyz { gap: 0px; } .framer-MezKP.framer-zdvyz > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-MezKP.framer-zdvyz > :first-child { margin-left: 0px; } .framer-MezKP.framer-zdvyz > :last-child { margin-right: 0px; } }", ".framer-MezKP[data-border=\"true\"]::after, .framer-MezKP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 62
 * @framerIntrinsicWidth 171
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermwfqnXBX2: React.ComponentType<Props> = withCSS(Component, css, "framer-MezKP") as typeof Component;
export default FramermwfqnXBX2;

FramermwfqnXBX2.displayName = "Tag 2";

FramermwfqnXBX2.defaultProps = {height: 62, width: 171};

addFonts(FramermwfqnXBX2, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})